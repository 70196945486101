<template>
    <div class="container border-container">
        <div class="search-container">
            <span class="list-title">教师列表</span>
            <el-button type="primary" icon="el-icon-plus" @click="handleDialogShow('')" v-if="userType === USER_TYPE.SCHOOL">添加任课教师</el-button>
        </div>
        <div class="table-container">
            <el-table :data="schoolTeacherList" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center" v-if="userType === USER_TYPE.SCHOOL"></el-table-column>
                <el-table-column label="教师名称" min-width="120" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        {{scope.row.user.name}}
                    </template>
                </el-table-column>
                <el-table-column label="教师电话" min-width="120"  align="center" >
                    <template slot-scope="scope">
                        {{scope.row.user.phone}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="50" align="center" v-if="userType === USER_TYPE.SCHOOL">
                    <template slot-scope="scope">
                        <!-- <el-button type="text" class="icon-btn" @click="handleDialogShow(scope.row)">
                            <el-tooltip placement="top" content="编辑">
                                <i class="iconfont icon-edit"></i>
                            </el-tooltip>
                        </el-button> -->
                        <el-button type="text" class="icon-btn" @click="handleDeleteTeacher(scope.row)">
                            <el-tooltip placement="top" content="移出班级">
                                <i class="iconfont icon-delete"></i>
                            </el-tooltip>
                        </el-button>
                        <!-- <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row.id)">
                            <el-tooltip placement="top" content="查看">
                                <i class="iconfont icon-check"></i>
                            </el-tooltip>
                        </el-button> -->
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <template v-if="userType === USER_TYPE.SCHOOL">
                <el-button type="primary" icon="iconfont icon-delete"
                    @click="handleDeleteSelectedTeacher()" round plain
                    :disabled="!teacherPickList.length">批量移出</el-button>
                <div class="selected-sum">
                    <span>已选中 {{teacherPickList.length || 0}} 条</span>
                </div>
            </template>
        </div>
        <el-dialog class="school-teacher-dialog"
            title="添加任课教师"
            :visible.sync="dialogVisible"
            :before-close="handleDialogClose">
            <div class="school-teacher-dialog-content">
                <div class="select-group">
                    <div class="select-group-item"
                        v-for="(item, index) of schoolTeacherListAll" :key="index"
                        :class="[item.user && teacherSelectedList.includes(item.user.id) ? 'selected' : '' ,
                                item.disabled ? 'disabled' : item.disabled ]"
                        @click="handleSelectTeacher(item.user, item.disabled)" >
                        <div class="left avatar">
                            <img v-if="item.user && item.user.avatar" :src="item.user.avatar" alt=""/>
                            <img v-else src="@/assets/images/default-avatar.png" alt=""/>
                        </div>
                        <div class="right teacher-info">
                            <div class="teacher-info-item">
                                <p><span>姓名：</span>{{item.user ? item.user.name : '-'}}</p>
                            </div>
                            <div class="teacher-info-item">
                                <p><span>手机：</span>{{item.user ? item.user.phone: '-'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <div class="left">
                    <el-pagination layout="prev, pager, next" background
                        :page-size="9" :pager-count="5"
                        :current-page="currentPageAll"
                        @current-change="pageChangeAll"
                        :total="totalAll">
                    </el-pagination>
                </div>
                <div class="right">
                    <el-button @click="handleDialogClose">取 消</el-button>
                    <el-button type="primary" @click="handleEditTeacher">确 定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from 'core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        schoolId: {
            type: [String, Number]
        },
        classId: {
            type: [Number,String],
            default: 0
        }
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,
            totalAll: 0,
            currentPageAll: 1,
            schoolTeacherList: [],
            schoolTeacherListAll: [],
            dialogVisible: false,
            teacherSelectedList:[], // 已有的教师的user_id
            teacherPickList:[],   // 选择 的 '已有的教师' 的 user_id
            search: {
                name: '',
            }
        };
    },
    created() {
        this.getSchoolTeacherList();
        this.getSchoolTeacherListAll();
    },
    methods: {
        pageChangeAll(page) { // 学校教师列表页码改变
            this.currentPageAll = page;
            this.getSchoolTeacherListAll();
        },
        getSchoolTeacherList() { // 获取 班级的教师列表
            Core.Api.SchoolClass.teacherList(this.classId).then((res) => {
                this.total = res.count
                this.schoolTeacherList = res.list
                this.teacherSelectedList = []
                for (let i = 0; i < res.list.length; i++) {
                    this.teacherSelectedList.push(res.list[i].user_id)
                }
            });
        },
        getSchoolTeacherListAll() { // 获取学校的教师列表
            Core.Api.SchoolAccount.list(
                this.schoolId,
                this.currentPageAll,
                9,
            ).then((res) => {
                this.totalAll = res.count
                this.schoolTeacherListAll = res.list
            });
        },
        handleDeleteTeacher(item) { // 删除 一位 教师
            this.$confirm('确定要将这位老师从班级移除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.SchoolClass.teacherOut(
                    this.classId,
                    item.user_id
                ).then((res) => {
                    this.$message.success('移除成功!');
                    this.getSchoolTeacherList()
                })
            })
        },
        
        handleSelectionChange(val) { // 表格的选择的改变
            this.teacherPickList = [];
            for (let i = 0; i < val.length; i++) {
                this.teacherPickList.push(val[i].user_id)
            }
        },
        handleDeleteSelectedTeacher() { // 删除 多位 教师
            this.$confirm('确定要将这几位教师从班级移除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.SchoolClass.teacherOut(
                    this.classId,
                    this.teacherPickList.join(',')
                ).then((res) => {
                    this.$message.success('移除成功!');
                    this.getSchoolTeacherList()
                })
            })
        },
        handleDialogShow() {  // 显示弹框
            for (let i = 0; i < this.schoolTeacherListAll.length; i++) {
                this.schoolTeacherListAll[i].disabled = false
                for (let j = 0; j < this.teacherSelectedList.length; j++) {
                    if (this.teacherSelectedList[j] === this.schoolTeacherListAll[i].user_id) {
                        this.schoolTeacherListAll[i].disabled = true
                        break;
                    }
                }
            }
            this.$nextTick(() => {
                this.dialogVisible = true
            })
        },
        handleDialogClose() { // 取消选择
            this.dialogVisible = false;
            this.pageChangeAll(1)
        },
        handleSelectTeacher(user,disabled) { // 选择教师
            if (!user || disabled){
                return
            }
            if (this.teacherSelectedList.includes(user.id)) {
                let index = this.teacherSelectedList.indexOf(user.id)
                this.teacherSelectedList.splice(index,1)
            } else {
                this.teacherSelectedList.push(user.id)
            }
        },
        handleEditTeacher() { // 确认 添加 教师
            this.dialogVisible = false
            Core.Api.SchoolClass.teacherBind(
                this.classId,
                this.teacherSelectedList.join(',')
            ).then((res) => {
                this.$message.success('添加成功!')
                this.pageChangeAll(1)  // 弹窗回到第一页
                this.getSchoolTeacherList()  // 更新展示表格
            })
        },
    },
    computed: {}

};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .search-container {
        @include flex(row, space-between, center);
    }
    .page-container {
        justify-content: flex-start;
    }
    .school-teacher-dialog{
        &-content{
            .select-group {
                width: 660px;
                margin: 0 auto;
                @include flex(row, flex-start, center);
                flex-wrap: wrap;
                &-item {
                    @include flex(row, space-between, center);
                    width: 200px;
                    height: 70px;
                    padding: 10px 7px;
                    margin: 10px;
                    box-sizing: border-box;
                    border: 1px solid rgb(185, 171, 171);
                    border-radius: 4px;
                    cursor: pointer;
                    .left{
                        width: 50px;
                        height: 50px;
                    }
                    .right{
                        width: calc(100% - 55px);
                    }
                    .avatar{
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    .teacher-info{
                        &-item{
                            margin-bottom: 7px;
                            &:last-child{
                                margin-bottom: 0px;
                            }
                        }
                    }
                    &.selected {
                        border-color: #1D517E;
                        background-color: rgba(29, 81, 126, 0.1);
                    }
                    &.disabled {
                        border-color: #1D517E;
                        background-color: rgba(29, 81, 126, 0.1);
                        cursor: no-drop;
                    }
                }
            }
        }
        .dialog-footer {
            @include flex(row, space-between, center);
            .left{
                @include flex(row, center, center);
                width: calc(100% - 244px);
                padding: 0 30px;
                box-sizing: border-box;
            }
            .right{
                width: 244px;
            }
        }
    }
}
</style>
<style lang="scss">
.container {
    .school-teacher-dialog{
        .el-dialog{
            width: 700px;
            border-radius: 5px;
            overflow: hidden;
            .el-dialog__header,.el-dialog__footer{
                background:rgba(248,249,250,1);
            }
            .el-dialog__header{
                border-bottom:1px solid rgba(234,237,240,1);
            }
            .el-dialog__body{
                padding-top: 10px;
                padding-bottom: 10px;
                height: 270px;
            }
            .el-dialog__footer{
                padding: 10px;
                border-top:1px solid rgba(234,237,240,1);
                
            }
        }
    }
}
</style>

